<template>
  <div class="other-utilites" v-if="data">
    <div class="banner-top">
      <div class="banner-top__bg">
        <img :src="data.header?.image" alt="" />
      </div>
    </div>
    <div class="category category-blue pc">
      <img src="../assets/img/logo/logo-category.svg" alt="" />
      <a class="before" href="#">Trang chủ</a>
      <span class="tag-mid">/</span>
      <a class="after" href="#">Tiện Ích Khác</a>
    </div>
    <div class="utilites-main">
      <div class="title" data-aos="fade-up" v-if="data.header">
        <h5>TIỆN ÍCH KHÁC</h5>
        <h1>{{ data.header[locale].title }}</h1>
        <p class="des">
          {{ data.header[locale].content }}
        </p>
      </div>
      <div class="content-room">
        <div class="content-room__bg" data-aos="fade-up">
          <img :src="data.main.image" alt="" />
        </div>
        <div class="content-room__room">
          <div class="club-house" v-if="data.main">
            <div class="club-house__left" data-aos="fade-up">
              <h5 class="name">{{ data.main[locale].type }}</h5>
              <h1 class="title" v-html="data.main[locale].name"></h1>
              <p class="time">
                Giờ Mở Cửa <br />{{ data.main.open_time[locale].opening_day }}
                <br />{{ data.main.open_time[locale].business_hours }}
              </p>
            </div>
            <div class="club-house__right" data-aos="fade-up">
              <div class="info">
                <span v-html="data.main[locale].description"></span>
                <br />
                <br />
                <span v-html="data.main[locale].content"></span>
              </div>
            </div>
          </div>
          <div
            class="pro-shop"
            v-if="data?.utilities && data.utilities.length > 0"
          >
            <div class="pro-shop__bg item-bg" data-aos="fade-up">
              <img :src="data.utilities[0].image" alt="" />
            </div>
            <div
              class="pro-shop__content item-content item-right"
              data-aos="fade-up"
            >
              <h5 class="name">{{ data.utilities[0][locale].type }}</h5>
              <h1 class="title">
                {{ data.utilities[0][locale].name }}
              </h1>
              <p class="info">
                <span v-html="data.utilities[0][locale].description"></span>
                <br />
                <br />
                <span v-html="data.utilities[0][locale].content"></span>
              </p>
              <p class="time">
                Giờ Mở Cửa <br />
                {{ data.utilities[0].open_time[locale].opening_day }} <br />
                {{ data.utilities[0].open_time[locale].business_hours }}
              </p>
            </div>
          </div>
          <div
            class="room-locker"
            v-if="data?.utilities && data.utilities.length > 1"
          >
            <div
              class="room-locker__content item-content item-left"
              data-aos="fade-up"
            >
              <h5 class="name">{{ data.utilities[1][locale].type }}</h5>
              <h1 class="title">
                {{ data.utilities[1][locale].name }}
              </h1>
              <p class="info">
                <span v-html="data.utilities[1][locale].description"></span>
                <br />
                <br />
                <span v-html="data.utilities[1][locale].content"></span>
              </p>
              <p class="time">
                Giờ Mở Cửa <br />
                {{ data.utilities[1].open_time[locale].opening_day }} <br />
                {{ data.utilities[1].open_time[locale].business_hours }}
              </p>
            </div>
            <div class="room-locker__bg item-bg" data-aos="fade-up">
              <img :src="data.utilities[1].image" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="related">
        <h3 class="related__title" data-aos="fade-up">Các Dịch Vụ Khác</h3>
        <div class="related__content" v-if="data.other_utilities">
          <div
            class="item"
            data-aos="fade-up"
            v-for="(item, index) in data.other_utilities"
            :key="index"
          >
            <div v-if="index % 2" class="item__bg bg-left">
              <img :src="item.image" alt="" />
            </div>

            <div v-if="index % 2" class="item__info content-right">
              <h5 class="title">{{ item[locale].name }}</h5>
              <p class="content" v-html="item[locale].description"></p>
              <p class="content" v-html="item[locale].content"></p>
            </div>
            <div v-if="!(index % 2)" class="item__info content-left">
              <h5 class="title">{{ item[locale].name }}</h5>
              <p class="content" v-html="item[locale].description"></p>
              <p class="content" v-html="item[locale].content"></p>
            </div>
            <div v-if="!(index % 2)" class="item__bg bg-right">
              <img :src="item.image" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UtilitesService from "@/services/UtilitesService";
import { mapGetters } from "vuex";
export default {
  components: {},
  data() {
    return {
      data: "",
    };
  },
  mounted() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.getOtherUtilities();
  },
  computed: {
    ...mapGetters(["locale"]),
  },
  methods: {
    async getOtherUtilities() {
      await UtilitesService.otherUtilities()
        .then((resp) => {
          this.data = resp.data.data;
        })
        .catch(() => {});
    },
  },
};
</script>
